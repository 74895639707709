import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/shared/layout"
import SEO from "../../components/SEO/SEOPage"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import {
  wrapper,
  featuredWrapper,
  noFeaturedWrapper,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
} from "../../utils/style"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import Parallax from "../../components/shared/Parallax"
import { lineCabinetLogin } from "../../components/Auth/Config"

const WrapperDesktop = styled("div")`
  ${wrapper}
`

const FeaturedWrapper = styled("div")`
  ${featuredWrapper}
  position: relative;
  max-height: 600px;
  overflow: hidden;
  margin-bottom: 30px;
  h1 {
    margin-top: 30px;
  }
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  img {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper,
    img {
      border-radius: 10px;
    }
  }
`

const NoFeaturedWrapper = styled("div")`
  ${noFeaturedWrapper}
  @media (max-width: ${breakpoints.sm}) {
    h1 {
      margin-bottom: 20px;
    }
  }
`

const ContentWrapper = styled("div")`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
  padding: 0;

  iframe {
    max-width: 100%;
  }
`

const SaButtonWrap = styled("div")`
  padding-bottom: 35px;
  text-align: center;
  @media (max-width: ${breakpoints.xs}) {
    .button {
      white-space: normal;
      height: auto;
      padding: 12px 25px;
      width: 100%;
    }
  }
`

const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const featuredMedia = currentPage.featured_media
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + "- " + process.env.OWNER
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : ""
  const socialImage = currentPage.featured_media?.localFile?.publicURL

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <Parallax />
      <WrapperDesktop>
        {featuredMedia &&
        featuredMedia.localFile &&
        featuredMedia.localFile.childImageSharp &&
        featuredMedia.localFile.childImageSharp.fluid ? (
          <FeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <Img
              fluid={featuredMedia.localFile.childImageSharp.fluid}
              alt={
                currentPage.featured_media.alt_text
                  ? currentPage.featured_media.alt_text
                  : page_title
              }
              fadeIn={false}
              loading="eager"
            />
            <h1 dangerouslySetInnerHTML={{ __html: page_title }} />
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <h1 dangerouslySetInnerHTML={{ __html: page_title }} />
          </NoFeaturedWrapper>
        )}
        <SaButtonWrap>
          <a
            className="button gradient-button"
            href={lineCabinetLogin}
            target="_blank"
            rel="noopener noreferrer"
          >
            LnwAsia Wallet ฝาก-ถอนเงินรูปแบบใหม่
          </a>
        </SaButtonWrap>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
        </ContentWrapper>
      </WrapperDesktop>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
